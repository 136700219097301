import React, { useEffect } from 'react'
import phone from 'assets/images/icons/phone.svg'
import mail from 'assets/images/icons/mail.svg'
import file from 'assets/images/icons/file.svg'
import link from 'assets/images/icons/link.svg'
import {
  StyledLabel,
  StyledPersonalButtonsContainer,
  StyledPersonalButtonsLabel,
  StyledPersonalDataContainer,
  StyledPersonalLongCol,
  StyledPersonalRow,
  StyledPersonalShortCol,
  StyledValue,
} from './LeadPersonalData.styled'
import Button from 'components/Button/Button'
import { useTranslation } from 'react-i18next'
import { generateLeadCSV } from 'common/utils/CSVGenerator'
import { useLazyGetLeadDocumentLinkQuery } from 'services/address-api'
import { useToastEffect } from 'components/Toast/hooks/useToastEffect'

const LeadPersonalData = ({ data }: any) => {
  const { t } = useTranslation()
  const anonimizedFlag = 'ANONYMIZED'

  const [getLeadDocumentLink, { data: leadLink, isSuccess, isError, isFetching }] =
    useLazyGetLeadDocumentLinkQuery()

  useEffect(() => {
    if (isSuccess && leadLink && !isFetching) {
      window.open(leadLink, '_blank')
    }
  }, [leadLink, isSuccess, isFetching])

  useToastEffect(t('leadModal.document.error.toast'), 'ERROR', [isError])

  const handleButtonClick = () => {
    getLeadDocumentLink({ id: data.id })
  }

  const handleCSVButtonClick = () => {
    generateLeadCSV(data, t)
  }

  const getPersonalData = (value?: string) => {
    if (value === anonimizedFlag) {
      return t('leadModal.anonymizedData')
    }
    return value
  }

  return (
    <StyledPersonalDataContainer>
      <StyledPersonalRow>
        <StyledPersonalShortCol>
          <StyledLabel>{t('leadModal.firstName')}</StyledLabel>
          <StyledValue>{getPersonalData(data?.leadDetails?.name)}</StyledValue>
        </StyledPersonalShortCol>
        <StyledPersonalLongCol>
          <StyledLabel>{t('leadModal.lastName')}</StyledLabel>
          <StyledValue>{getPersonalData(data?.leadDetails?.surname)}</StyledValue>
        </StyledPersonalLongCol>
      </StyledPersonalRow>
      <StyledPersonalRow>
        <StyledPersonalShortCol>
          <StyledLabel>{t('leadModal.phone')}</StyledLabel>
          <StyledValue>
            <img src={phone} alt="phone" />
            {getPersonalData(data?.leadDetails?.phone)}
          </StyledValue>
        </StyledPersonalShortCol>
        <StyledPersonalLongCol>
          <StyledLabel>{t('leadModal.email')}</StyledLabel>
          <StyledValue>
            <img src={mail} alt="mail" />
            {getPersonalData(data?.leadDetails?.email)}
          </StyledValue>
        </StyledPersonalLongCol>
      </StyledPersonalRow>
      <StyledPersonalRow>
        <StyledPersonalShortCol>
          <StyledLabel>{t('leadModal.origin')}</StyledLabel>
          <StyledValue>
            <img src={link} alt="link" />
            {data?.leadDetails?.origin || '-'}
          </StyledValue>
        </StyledPersonalShortCol>
        <StyledPersonalLongCol>
          <StyledLabel>{t('leadModal.document')}</StyledLabel>
          <StyledValue withButtons>
            <StyledPersonalButtonsLabel>
              <img src={file} alt="file" />
              {t('leadModal.propertyDocuments')}
            </StyledPersonalButtonsLabel>
            <StyledPersonalButtonsContainer>
              <>
                <Button onClick={() => handleButtonClick()}>{t('common.showButton')}</Button>
                <Button onClick={handleCSVButtonClick}>{t('leadModal.csvButton')}</Button>
              </>
            </StyledPersonalButtonsContainer>
          </StyledValue>
        </StyledPersonalLongCol>
      </StyledPersonalRow>
    </StyledPersonalDataContainer>
  )
}

export default LeadPersonalData
