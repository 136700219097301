import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { requiredFields } from 'common/utils/RequiredFields'
import _ from 'lodash'
import Button from 'components/Button/Button'
import SprengnetterAppsReport from 'assets/images/icons/SprengnetterAppsReport.png'
import SprengnetterAppsIndex from 'assets/images/icons/SprengnetterAppsIndex.png'
import SprengnetterAppsMaps from 'assets/images/icons/SprengnetterAppsMaps1x.png'
import {
  StyledSwitchTabsObjectDataButtonContainer,
  StyledSwitchTabsAddressButtonContainer,
  StyledSwitchTabsEquipmentButtonContainer,
  StyledAddressButtonReport,
  StyledAddressButtonMaps,
  StyledAddressButtonsContainer,
  StyledAddressButtonIndex,
} from './ModalSwitchButtons.styled'
import { useTranslation } from 'react-i18next'
import { findInArray } from 'common/utils/generic'
import { sanitizeAddressData } from './helper'
import { selectCustomerConfiguration } from 'slices/customerConfigurationSlice'
import {
  selectAddressFormData,
  setAddressData,
  setProductName,
  setValidationErrors,
} from 'slices/addressFormSlice'
import { useCreateAddressMutation, useUpdateAddressMutation } from 'services/address-api'
import { useAppDispatch } from 'store/hooks'
import EquipmentLevelCalculation from './EquipmentLevelCalculation/EquipmentLevelCalculation'
import { useToastEffect } from 'components/Toast/hooks/useToastEffect'
import { ObjectType } from 'types/ApiResponse'

export interface ModalSwitchButtonsProps {
  value: number
  setValue: Dispatch<SetStateAction<number>>
  handleSetNextButton: Dispatch<any>
}

const ModalSwitchButtons = (props: ModalSwitchButtonsProps) => {
  const { value, setValue, handleSetNextButton } = props
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const addressFormData = useSelector(selectAddressFormData)
  const customerConfig = useSelector(selectCustomerConfiguration)
  const nextButton = useRef()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const isIndex = addressFormData.productName === 'index'
  const validationRequiredFieldsPage2ETW = requiredFields['page2ETW']
  const validationRequiredFieldsPage2EFH = requiredFields['page2EFH']
  const validationRequiredFieldsPage2MFH = requiredFields['page2MFH']
  const priority =
    (customerConfig?.configuration?.priority &&
      JSON.parse(customerConfig?.configuration?.priority)) ||
    {}
  const indicesProduct = findInArray(customerConfig?.products, 'name', 'indices')
  const reportProduct = findInArray(customerConfig?.products, 'name', 'report')
  const mapsProduct = findInArray(customerConfig?.products, 'name', 'maps')

  const [
    createAddress,
    {
      isLoading: isCrateAddressLoading,
      isSuccess: isCrateAddressSuccess,
      isError: isCrateAddressError,
      data: createAddressData,
    },
  ] = useCreateAddressMutation()

  const [
    updateAddress,
    {
      isLoading: isUpdateAddressLoading,
      isSuccess: isUpdateAddressSuccess,
      isError: isUpdateAddressError,
      data: updateAddressData,
    },
  ] = useUpdateAddressMutation()

  useEffect(() => {
    if (isCrateAddressSuccess) {
      dispatch(setAddressData(createAddressData))
    }
  }, [isCrateAddressSuccess, createAddressData, dispatch])

  useEffect(() => {
    if (isUpdateAddressSuccess) {
      dispatch(setAddressData(updateAddressData))
    }
  }, [isUpdateAddressSuccess, updateAddressData, dispatch])

  useEffect(() => {
    if (!isSubmitted) return
    if (!nextButton.current) {
      handleSetNextButton(nextButton)
    }
    if (!isIndex && addressFormData.validationErrors.length === 0 && !addressFormData.isEdit) {
      const { livingArea, plotArea, rooms } = addressFormData.addressData.propertyDetails
      const formattedLivingArea =
        typeof livingArea === 'string' && livingArea.includes(',')
          ? livingArea.replace(',', '.')
          : livingArea
      const formattedPlotArea =
        typeof plotArea === 'string' && plotArea.includes(',')
          ? plotArea.replace(',', '.')
          : plotArea
      const formattedRooms =
        typeof rooms === 'string' && rooms.includes(',') ? rooms.replace(',', '.') : rooms

      const payload = JSON.parse(JSON.stringify(addressFormData.addressData))

      if (livingArea) {
        payload.propertyDetails.livingArea = parseFloat(formattedLivingArea)
      }

      if (plotArea) {
        payload.propertyDetails.plotArea = parseFloat(formattedPlotArea)
      }

      if (rooms) {
        payload.propertyDetails.rooms = parseFloat(formattedRooms)
      }
      createAddress(sanitizeAddressData(payload))
    } else if (
      !isIndex &&
      addressFormData.validationErrors.length === 0 &&
      value === (!isIndex ? 2 : 1) &&
      addressFormData.isEdit
    ) {
      updateAddress({
        id: addressFormData.addressData.id,
        payload: sanitizeAddressData(addressFormData.addressData),
      })
    }
    setIsSubmitted(false)
  }, [
    addressFormData,
    isSubmitted,
    createAddress,
    handleSetNextButton,
    isIndex,
    updateAddress,
    value,
  ])

  useEffect(() => {
    if (!isSubmitted) return
    if (isIndex && addressFormData.validationErrors.length === 0) {
      const data = sanitizeAddressData(addressFormData.addressData)
      if (addressFormData.isEdit) {
        updateAddress({
          id: addressFormData.addressData.id,
          payload: data,
        })
      } else {
        createAddress(data)
      }
    }
    setIsSubmitted(false)
  }, [addressFormData, isSubmitted, createAddress, updateAddress, isIndex])

  useEffect(() => {
    if (isCrateAddressSuccess || isUpdateAddressSuccess) {
      setValue((v) => v + 1)
    }
  }, [isCrateAddressSuccess, isUpdateAddressSuccess, setValue])

  const onButtonClick = (productName: string) => {
    if (!addressFormData?.addressData?.houseNumber) {
      return
    }
    dispatch(setProductName(productName))
    setValue(value + 1)
  }

  const validateFields = (actualData, incomingValueLead, page) => {
    const incomingValue = { ...incomingValueLead }

    function flattenObject(obj, prefix = '') {
      const result = {}
      for (const [key, value] of Object.entries(obj)) {
        const newKey = prefix ? `${prefix}.${key}` : key
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
          Object.assign(result, flattenObject(value, newKey))
        } else {
          result[newKey] = value
        }
      }
      return result
    }

    for (const [key] of Object.entries(incomingValue)) {
      const keys = key.split('.')
      let current = incomingValue
      for (let i = 0; i < keys.length; i++) {
        if (i === keys.length - 1) {
          if (typeof current[keys[i]] === 'undefined') {
            delete current[keys[i]]
          }
        } else {
          current = current[keys[i]]
        }
      }
    }

    const flattenedIncoming = flattenObject(incomingValue)
    const inc = Object.keys(flattenedIncoming)
    const dif = _.difference(actualData, inc)
    dispatch(setValidationErrors(dif))
    if (page === '2' && !isIndex && dif.length === 0) {
      setValue(value + 1)
    }
  }

  const handleValidationButtonClick = (page: any) => {
    if (page === '2') {
      if (isIndex) {
        validateFields(requiredFields.page2Index, addressFormData.addressData, page)
        setIsSubmitted(true)
      } else if (
        addressFormData.addressData?.propertyDetails?.objectType === ObjectType.APARTMENT
      ) {
        validateFields(validationRequiredFieldsPage2ETW, addressFormData.addressData, page)
      } else if (addressFormData.addressData?.propertyDetails?.objectType === ObjectType.HOUSE) {
        validateFields(validationRequiredFieldsPage2EFH, addressFormData.addressData, page)
      } else {
        validateFields(validationRequiredFieldsPage2MFH, addressFormData.addressData, page)
      }
    }

    if (page === '3') {
      if (addressFormData.addressData?.propertyDetails?.objectType === ObjectType.APARTMENT) {
        validateFields(validationRequiredFieldsPage2ETW, addressFormData.addressData, page)
      } else if (addressFormData.addressData?.propertyDetails?.objectType === ObjectType.HOUSE) {
        validateFields(validationRequiredFieldsPage2EFH, addressFormData.addressData, page)
      } else {
        validateFields(validationRequiredFieldsPage2MFH, addressFormData.addressData, page)
      }
      setIsSubmitted(true)
    }
  }

  useToastEffect(t('newAddressModal.addressTab.create-address.error.toast'), 'ERROR', [
    isCrateAddressError || isUpdateAddressError,
  ])

  useToastEffect(t('newAddressModal.addressTab.create-address.success.toast'), 'INFO', [
    isCrateAddressSuccess,
  ])

  return (
    <>
      {value === 0 && addressFormData?.addressData?.houseNumber?.length > 0 && (
        <StyledSwitchTabsAddressButtonContainer>
          <p>{t('newAddressModal.addressTab.options')}</p>
          <StyledAddressButtonsContainer>
            {indicesProduct?.active && (
              <StyledAddressButtonIndex order={priority[indicesProduct?.id] || 0}>
                <img src={SprengnetterAppsIndex} alt={SprengnetterAppsIndex} />
                <Button kind="secondary" onClick={() => onButtonClick('index')}>
                  Index
                </Button>
              </StyledAddressButtonIndex>
            )}
            {reportProduct?.active && (
              <StyledAddressButtonReport order={priority[reportProduct?.id] || 0}>
                <img src={SprengnetterAppsReport} alt={SprengnetterAppsReport} />
                <Button kind="gray" onClick={() => onButtonClick('report')}>
                  Report
                </Button>
              </StyledAddressButtonReport>
            )}
            {mapsProduct?.active && (
              <StyledAddressButtonMaps order={priority[mapsProduct?.id] || 0}>
                <img src={SprengnetterAppsMaps} alt={SprengnetterAppsMaps} />
                <Button
                  kind="orange"
                  onClick={() => {
                    onButtonClick('map')
                  }}
                >
                  Maps
                </Button>
              </StyledAddressButtonMaps>
            )}
          </StyledAddressButtonsContainer>
        </StyledSwitchTabsAddressButtonContainer>
      )}
      {value === 1 && (
        <StyledSwitchTabsObjectDataButtonContainer
          ref={nextButton}
          onClick={() => handleValidationButtonClick('2')}
        >
          <Button
            kind="primary"
            loading={isCrateAddressLoading || isUpdateAddressLoading}
            disabled={isCrateAddressLoading || isUpdateAddressLoading}
          >
            {isCrateAddressLoading || isUpdateAddressLoading
              ? t('common.loadingButton')
              : t('common.continueButton')}
          </Button>
        </StyledSwitchTabsObjectDataButtonContainer>
      )}
      {value === 2 && !isIndex && (
        <StyledSwitchTabsEquipmentButtonContainer>
          <Button
            onClick={() => {
              setValue(value > 0 ? value - 1 : 0)
            }}
            kind="secondary"
          >
            {t('common.backButton')}
          </Button>
          <EquipmentLevelCalculation />
          <Button
            kind="primary"
            onClick={() => handleValidationButtonClick('3')}
            loading={isCrateAddressLoading || isUpdateAddressLoading}
            disabled={isCrateAddressLoading || isUpdateAddressLoading}
          >
            {isCrateAddressLoading || isUpdateAddressLoading
              ? t('common.loadingButton')
              : t('common.continueButton')}
          </Button>
        </StyledSwitchTabsEquipmentButtonContainer>
      )}
    </>
  )
}

export default ModalSwitchButtons
