import { EquipmentMethod } from 'types/EquipmentMethod'
import {
  bathroomEFH,
  bathroomETW,
  floorETW,
  residentialAreaETW,
  residentialAreaMFH,
  roofCoveringEFHandMFH,
  storeETW,
  storeMFH,
  windowsType,
} from '../AddEquipment/AddEquipmentMultiSelect/helper'
import {
  guestToilet,
  surfaceMountedInstalationEFH,
} from '../AddEquipment/AddEquipmentRadioGroup/helper'
import {
  hausTypeEFHandMFH,
  hausTypeETW,
} from '../AddObjectData/AddObjectDataComponents/AddHouseParameters/helper'
import { BuildingType, ObjectType } from 'types/ApiResponse'

const getEnumValue = (value: any, enums: any, valueField: string) => {
  if (value === null || value === undefined) {
    return value
  }
  const validValue = enums.find((item: any) => item[valueField] === value)
  if (validValue !== undefined) {
    return value
  } else {
    return null
  }
}

const getBuildingType = (objectType: ObjectType) => {
  switch (objectType) {
    case ObjectType.APARTMENT:
      return BuildingType.APARTMENT
    case ObjectType.HOUSE:
      return BuildingType.SINGLE_FAMILY_HOUSE
    case ObjectType.MULTI_FAMILY_HOUSE:
      return BuildingType.MULTI_FAMILY_HOUSE
    case ObjectType.PLOT_OF_LAND:
      return null
  }
}

export const getEquipmentLevelRequest = (addressData: any) => {
  const { propertyDetails, leadDetails, indexDetails, ...address } = addressData
  const { equipmentMatrix, ...propertyDetailsRest } = propertyDetails
  return {
    ...address,
    ...propertyDetailsRest,
    ...equipmentMatrix,
  }
}

export const sanitizeAddressData = (addressData: any) => {
  let result = Object.assign(
    {},
    {
      id: addressData.id,
      nation: addressData.nation,
      zip: addressData.zip,
      town: addressData.town,
      street: addressData.street,
      houseNumber: addressData.houseNumber,
      lat: addressData.lat,
      lng: addressData.lng,
      isCopy: addressData.isCopy,
      propertyDetails: {
        objectType: addressData.propertyDetails.objectType,
        buildingType: addressData.propertyDetails?.buildingType
          ? addressData.propertyDetails?.buildingType
          : getBuildingType(addressData.propertyDetails.objectType),
        constructionYear: addressData.propertyDetails.constructionYear,
        livingArea: addressData.propertyDetails.livingArea,
        garages: addressData.propertyDetails.garages,
        outdoorParkingSpace: addressData.propertyDetails.outdoorParkingSpace,
        equipmentValue: addressData.propertyDetails.equipmentValue,
        equipmentMethod: addressData.propertyDetails.equipmentMethod,
        ...(addressData.propertyDetails.equipmentMatrix
          ? {
              equipmentMatrix: {
                insulatedExteriorWalls:
                  addressData.propertyDetails.equipmentMatrix.insulatedExteriorWalls,
                heating: addressData.propertyDetails.equipmentMatrix.heating,
              },
            }
          : {}),
        reportImage: addressData.propertyDetails.reportImage,
        reportImageMimeType: addressData.propertyDetails.reportImageMimeType,
      },
      ...(addressData.indexDetails
        ? {
            indexDetails: {
              reportType: addressData.indexDetails.reportType,
              periodType: addressData.indexDetails.periodType,
              includeContactDetails: addressData.indexDetails.includeContactDetails,
            },
          }
        : {}),
    }
  )
  switch (addressData.propertyDetails.objectType) {
    case ObjectType.HOUSE:
      result = Object.assign(result, {
        propertyDetails: {
          ...result.propertyDetails,
          houseOrApartmentType: getEnumValue(
            addressData.propertyDetails.houseOrApartmentType,
            hausTypeEFHandMFH,
            'value'
          ),
          plotArea: addressData.propertyDetails.plotArea,
          elevator: null,
          rooms: addressData.propertyDetails.rooms,
          floorNumber: null,
          numberOfFloors: addressData.propertyDetails.numberOfFloors,
          ...(addressData.propertyDetails.equipmentMatrix
            ? {
                equipmentMatrix: {
                  ...result.propertyDetails.equipmentMatrix,
                  bathroom: getEnumValue(
                    addressData.propertyDetails.equipmentMatrix.bathroom,
                    bathroomEFH,
                    'value'
                  ),
                  roofCovering: getEnumValue(
                    addressData.propertyDetails.equipmentMatrix.roofCovering,
                    roofCoveringEFHandMFH,
                    'value'
                  ),
                  windows: getEnumValue(
                    addressData.propertyDetails.equipmentMatrix.windows,
                    windowsType,
                    'value'
                  ),
                  guestToilet: getEnumValue(
                    addressData.propertyDetails.equipmentMatrix.guestToilet,
                    guestToilet,
                    'key'
                  ),
                  storeRoom: null,
                  surfaceMountedInstallation: getEnumValue(
                    addressData.propertyDetails.equipmentMatrix.surfaceMountedInstallation,
                    surfaceMountedInstalationEFH,
                    'key'
                  ),
                  residentialArea: null,
                  floorCovering: null,
                },
              }
            : {}),
        },
      })
      break
    case ObjectType.APARTMENT:
      result = Object.assign(result, {
        propertyDetails: {
          ...result.propertyDetails,
          houseOrApartmentType: getEnumValue(
            addressData.propertyDetails.houseOrApartmentType,
            hausTypeETW,
            'value'
          ),
          plotArea: null,
          elevator: addressData.propertyDetails.elevator,
          rooms: addressData.propertyDetails.rooms,
          floorNumber: addressData.propertyDetails.floorNumber,
          numberOfFloors: null,
          ...(addressData.propertyDetails.equipmentMatrix
            ? {
                equipmentMatrix: {
                  ...result.propertyDetails.equipmentMatrix,
                  bathroom: getEnumValue(
                    addressData.propertyDetails.equipmentMatrix.bathroom,
                    bathroomETW,
                    'value'
                  ),
                  roofCovering: null,
                  windows: getEnumValue(
                    addressData.propertyDetails.equipmentMatrix.windows,
                    windowsType,
                    'value'
                  ),
                  guestToilet: getEnumValue(
                    addressData.propertyDetails.equipmentMatrix.guestToilet,
                    guestToilet,
                    'key'
                  ),
                  storeRoom: getEnumValue(
                    addressData.propertyDetails.equipmentMatrix.windows,
                    storeETW,
                    'value'
                  ),
                  surfaceMountedInstallation: null,
                  residentialArea: getEnumValue(
                    addressData.propertyDetails.residentialArea,
                    residentialAreaETW,
                    'value'
                  ),
                  floorCovering: getEnumValue(
                    addressData.propertyDetails.equipmentMatrix.floorCovering,
                    floorETW,
                    'value'
                  ),
                },
              }
            : {}),
        },
      })
      break
    case ObjectType.MULTI_FAMILY_HOUSE:
      result = Object.assign(result, {
        propertyDetails: {
          ...result.propertyDetails,
          houseOrApartmentType: getEnumValue(
            addressData.propertyDetails.houseOrApartmentType,
            hausTypeEFHandMFH,
            'value'
          ),
          plotArea: addressData.propertyDetails.plotArea,
          elevator: null,
          rooms: null,
          floorNumber: null,
          numberOfFloors: addressData.propertyDetails.numberOfFloors,
          ...(addressData.propertyDetails.equipmentMatrix
            ? {
                equipmentMatrix: {
                  ...result.propertyDetails.equipmentMatrix,
                  bathroom: null,
                  roofCovering: getEnumValue(
                    addressData.propertyDetails.equipmentMatrix.roofCovering,
                    roofCoveringEFHandMFH,
                    'value'
                  ),
                  windows: getEnumValue(
                    addressData.propertyDetails.equipmentMatrix.windows,
                    windowsType,
                    'value'
                  ),
                  guestToilet: getEnumValue(
                    addressData.propertyDetails.equipmentMatrix.guestToilet,
                    guestToilet,
                    'key'
                  ),
                  storeRoom: getEnumValue(
                    addressData.propertyDetails.equipmentMatrix.windows,
                    storeMFH,
                    'value'
                  ),
                  surfaceMountedInstallation: null,
                  residentialArea: getEnumValue(
                    addressData.propertyDetails.residentialArea,
                    residentialAreaMFH,
                    'value'
                  ),
                  floorCovering: null,
                },
              }
            : {}),
        },
      })
      break
  }

  if (result.propertyDetails.equipmentMethod === EquipmentMethod.DETAILED) {
    delete result.propertyDetails.equipmentValue
  }

  return result
}
