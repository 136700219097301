import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useGetEquipmentLevelMutation } from 'services/address-api'
import { selectAddressFormData, setEquipmentDetailsChanged } from 'slices/addressFormSlice'
import { useAppDispatch } from 'store/hooks'
import { getEquipmentLevelRequest, sanitizeAddressData } from '../helper'
import { EquipmentMethod } from 'types/EquipmentMethod'
import Button from 'components/Button/Button'
import { ReactComponent as WarningIcon } from 'assets/images/icons/warning.svg'
import { StyledWarningIcon } from './EquipmentLevelCalculation.styles'
import { useToastEffect } from 'components/Toast/hooks/useToastEffect'

const EquipmentLevelCalculation = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const addressFormData = useSelector(selectAddressFormData)
  const [equipmentLevelPreview, setEquipmentLevelPreview] = useState(undefined)
  const [showToastError, setShowToastError] = useState(false)

  const [getEquipmentLevel, { isLoading, isSuccess, isError, data, reset }] =
    useGetEquipmentLevelMutation()

  useEffect(() => {
    if (isSuccess) {
      setShowToastError(false)
      setEquipmentLevelPreview(data.replaceAll('"', ''))
      reset()
      if (addressFormData.equipmentDetailsChanged) {
        dispatch(setEquipmentDetailsChanged(false))
      }
    }
  }, [isSuccess, data, addressFormData.equipmentDetailsChanged, dispatch, reset])

  const calculateEquipmentLevel = async () => {
    if (isError) {
      setShowToastError(true)
    }
    getEquipmentLevel(getEquipmentLevelRequest(sanitizeAddressData(addressFormData.addressData)))
  }

  useToastEffect(t('newAddressModal.furnishingTab.equipmentPreview.error.toast'), 'ERROR', [
    isError,
    showToastError,
  ])

  return (
    <>
      {addressFormData.addressData?.propertyDetails?.equipmentMethod ===
        EquipmentMethod.DETAILED && (
        <div className="calculate-rating">
          <span>{t('newAddressModal.furnishingTab.equipmentPreview.label')}: </span>
          {equipmentLevelPreview === undefined ? (
            <>
              {(isError || showToastError) && (
                <span className="rating">
                  <StyledWarningIcon>
                    <WarningIcon height={30} width={30} />
                  </StyledWarningIcon>
                </span>
              )}
              <Button
                kind="secondary"
                onClick={() => (isLoading ? {} : calculateEquipmentLevel())}
                loading={isLoading}
              >
                {t('newAddressModal.furnishingTab.equipmentPreview.calculate')}
              </Button>
            </>
          ) : (
            <>
              <span className="rating">
                {isError || showToastError ? (
                  <StyledWarningIcon>
                    <WarningIcon height={30} width={30} />
                  </StyledWarningIcon>
                ) : (
                  t(`equipmentValue.${equipmentLevelPreview.toLowerCase()}`)
                )}
              </span>
              {addressFormData.equipmentDetailsChanged === true && (
                <Button
                  kind="secondary"
                  onClick={() => (isLoading ? {} : calculateEquipmentLevel())}
                  loading={isLoading}
                >
                  {t('newAddressModal.furnishingTab.equipmentPreview.refresh')}
                </Button>
              )}
            </>
          )}
        </div>
      )}
    </>
  )
}

export default EquipmentLevelCalculation
