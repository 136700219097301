import { EquipmentMatrixSurfaceMountedInstallation } from 'types/ApiResponse'

const confirmText = 'common.yes'
const declineText = 'common.no'

export const insulatedWalls = [
  { key: 'true', value: confirmText },
  { key: 'false', value: declineText },
]

export const guestToilet = [
  { key: 'true', value: confirmText },
  { key: 'false', value: declineText },
]
export const surfaceMountedInstalationEFH = [
  { key: EquipmentMatrixSurfaceMountedInstallation.CABLES_ON_WALLS, value: confirmText },
  { key: EquipmentMatrixSurfaceMountedInstallation.CABLES_NOT_ON_WALLS, value: declineText },
]
