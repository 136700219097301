import React from 'react'
import SprengnetterAppsLead from 'assets/images/icons/SprengnetterAppsLead.png'

import {
  StyledAddress,
  StyledAddressRow,
  StyledIcon,
  StyledLeadAddresContainer,
} from './Address.styled'
import { useTranslation } from 'react-i18next'

const Address = ({ data }: any) => {
  const { t } = useTranslation()
  return (
    <StyledLeadAddresContainer>
      <StyledIcon>
        <img src={SprengnetterAppsLead} alt="SprengnetterAppsLead" />
      </StyledIcon>
      <StyledAddress>
        <StyledAddressRow>
          {data.street} {data.houseNumber}
        </StyledAddressRow>
        <StyledAddressRow>
          {data.zip} {data.town}, {data.nation}{' '}
          <p>{t(`objectType.${data.propertyDetails.objectType.toLowerCase()}.tag`)}</p>
        </StyledAddressRow>
        <StyledAddressRow>
          {data.lat?.toFixed(6)}, {data.lng?.toFixed(6)}
        </StyledAddressRow>
      </StyledAddress>
    </StyledLeadAddresContainer>
  )
}

export default Address
