import {
  EquipmentMatrixBathroom,
  EquipmentMatrixFloorCovering,
  EquipmentMatrixHeating,
  EquipmentMatrixResidentialArea,
  EquipmentMatrixRoofCovering,
  EquipmentMatrixStoreRoom,
  EquipmentMatrixWindows,
} from 'types/ApiResponse'

export const heatingType = [
  {
    label: `equipmentMatrix.heating.${EquipmentMatrixHeating.FLOOR_HEATING.toLowerCase()}`,
    value: EquipmentMatrixHeating.FLOOR_HEATING,
  },
  {
    label: `equipmentMatrix.heating.${EquipmentMatrixHeating.STOVES_HEATING.toLowerCase()}`,
    value: EquipmentMatrixHeating.STOVES_HEATING,
  },
  {
    label: `equipmentMatrix.heating.${EquipmentMatrixHeating.CENTRAL_HEATING.toLowerCase()}`,
    value: EquipmentMatrixHeating.CENTRAL_HEATING,
  },
  {
    label: `equipmentMatrix.heating.${EquipmentMatrixHeating.OTHER.toLowerCase()}`,
    value: EquipmentMatrixHeating.OTHER,
  },
]

export const roofCoveringEFHandMFH = [
  {
    label: `equipmentMatrix.roofCovering.${EquipmentMatrixRoofCovering.ROOFING_FELT.toLowerCase()}`,
    value: EquipmentMatrixRoofCovering.ROOFING_FELT,
  },
  {
    label: `equipmentMatrix.roofCovering.${EquipmentMatrixRoofCovering.ROOFING_TILES.toLowerCase()}`,
    value: EquipmentMatrixRoofCovering.ROOFING_TILES,
  },
  {
    label: `equipmentMatrix.roofCovering.${EquipmentMatrixRoofCovering.METAL.toLowerCase()}`,
    value: EquipmentMatrixRoofCovering.METAL,
  },
  {
    label: `equipmentMatrix.roofCovering.${EquipmentMatrixRoofCovering.SLATE.toLowerCase()}`,
    value: EquipmentMatrixRoofCovering.SLATE,
  },
  {
    label: `equipmentMatrix.roofCovering.${EquipmentMatrixRoofCovering.OTHER.toLowerCase()}`,
    value: EquipmentMatrixRoofCovering.OTHER,
  },
]

export const windowsType = [
  {
    label: `equipmentMatrix.windows.${EquipmentMatrixWindows.DOUBLE_GLAZING.toLowerCase()}`,
    value: EquipmentMatrixWindows.DOUBLE_GLAZING,
  },
  {
    label: `equipmentMatrix.windows.${EquipmentMatrixWindows.TRIPLE_GLAZING.toLowerCase()}`,
    value: EquipmentMatrixWindows.TRIPLE_GLAZING,
  },
  {
    label: `equipmentMatrix.windows.${EquipmentMatrixWindows.SPECIAL_GLAZING.toLowerCase()}`,
    value: EquipmentMatrixWindows.SPECIAL_GLAZING,
  },
  {
    label: `equipmentMatrix.windows.${EquipmentMatrixWindows.FLOOR_TO_CEILING_GLAZING.toLowerCase()}`,
    value: EquipmentMatrixWindows.FLOOR_TO_CEILING_GLAZING,
  },
  {
    label: `equipmentMatrix.windows.${EquipmentMatrixWindows.BOX_WINDOW.toLowerCase()}`,
    value: EquipmentMatrixWindows.BOX_WINDOW,
  },
  {
    label: `equipmentMatrix.windows.${EquipmentMatrixWindows.SIMPLE.toLowerCase()}`,
    value: EquipmentMatrixWindows.SIMPLE,
  },
  {
    label: `equipmentMatrix.windows.${EquipmentMatrixRoofCovering.OTHER.toLowerCase()}`,
    value: EquipmentMatrixRoofCovering.OTHER,
  },
]

export const storeETW = [
  {
    label: `equipmentMatrix.storeRoom.${EquipmentMatrixStoreRoom.OUTSIDE.toLowerCase()}`,
    value: EquipmentMatrixStoreRoom.OUTSIDE,
  },
  {
    label: `equipmentMatrix.storeRoom.${EquipmentMatrixStoreRoom.INSIDE.toLowerCase()}`,
    value: EquipmentMatrixStoreRoom.INSIDE,
  },
  {
    label: `equipmentMatrix.storeRoom.${EquipmentMatrixStoreRoom.OUTSIDE_AND_INSIDE.toLowerCase()}`,
    value: EquipmentMatrixStoreRoom.OUTSIDE_AND_INSIDE,
  },
  {
    label: `equipmentMatrix.storeRoom.${EquipmentMatrixStoreRoom.NONE.toLowerCase()}`,
    value: EquipmentMatrixStoreRoom.NONE,
  },
]

export const storeMFH = [
  {
    label: `equipmentMatrix.storeRoom.${EquipmentMatrixStoreRoom.OUTSIDE_AND_INSIDE.toLowerCase()}`,
    value: EquipmentMatrixStoreRoom.OUTSIDE_AND_INSIDE,
  },
  {
    label: `equipmentMatrix.storeRoom.${EquipmentMatrixStoreRoom.NONE.toLowerCase()}`,
    value: EquipmentMatrixStoreRoom.NONE,
  },
]

export const floorETW = [
  {
    label: `equipmentMatrix.floorCovering.${EquipmentMatrixFloorCovering.TILES.toLowerCase()}`,
    value: EquipmentMatrixFloorCovering.TILES,
  },
  {
    label: `equipmentMatrix.floorCovering.${EquipmentMatrixFloorCovering.PLASTIC_PVC.toLowerCase()}`,
    value: EquipmentMatrixFloorCovering.PLASTIC_PVC,
  },
  {
    label: `equipmentMatrix.floorCovering.${EquipmentMatrixFloorCovering.PARQUET_NATURAL_STONE.toLowerCase()}`,
    value: EquipmentMatrixFloorCovering.PARQUET_NATURAL_STONE,
  },
  {
    label: `equipmentMatrix.floorCovering.${EquipmentMatrixFloorCovering.CARPET_LAMINATE.toLowerCase()}`,
    value: EquipmentMatrixFloorCovering.CARPET_LAMINATE,
  },
  {
    label: `equipmentMatrix.floorCovering.${EquipmentMatrixFloorCovering.OTHER.toLowerCase()}`,
    value: EquipmentMatrixFloorCovering.OTHER,
  },
]

export const residentialAreaETW = [
  {
    label: `equipmentMatrix.residentialArea.${EquipmentMatrixResidentialArea.WINTER_GARDEN.toLowerCase()}`,
    value: EquipmentMatrixResidentialArea.WINTER_GARDEN,
  },
  {
    label: `equipmentMatrix.residentialArea.${EquipmentMatrixResidentialArea.BALCONY_ABOVE_10.toLowerCase()}`,
    value: EquipmentMatrixResidentialArea.BALCONY_ABOVE_10,
  },
  {
    label: `equipmentMatrix.residentialArea.${EquipmentMatrixResidentialArea.BALCONY_UNDER_10.toLowerCase()}`,
    value: EquipmentMatrixResidentialArea.BALCONY_UNDER_10,
  },
  {
    label: `equipmentMatrix.residentialArea.${EquipmentMatrixResidentialArea.NO_BALCONY.toLowerCase()}`,
    value: EquipmentMatrixResidentialArea.NO_BALCONY,
  },
]

export const residentialAreaMFH = [
  {
    label: `equipmentMatrix.residentialArea.${EquipmentMatrixResidentialArea.BALCONY_ABOVE_10.toLowerCase()}`,
    value: EquipmentMatrixResidentialArea.BALCONY_ABOVE_10,
  },
  {
    label: `equipmentMatrix.residentialArea.${EquipmentMatrixResidentialArea.BALCONY_UNDER_10.toLowerCase()}`,
    value: EquipmentMatrixResidentialArea.BALCONY_UNDER_10,
  },
  {
    label: `equipmentMatrix.residentialArea.${EquipmentMatrixResidentialArea.NO_BALCONY.toLowerCase()}`,
    value: EquipmentMatrixResidentialArea.NO_BALCONY,
  },
]

export const bathroomEFH = [
  {
    label: `equipmentMatrix.bathroom.${EquipmentMatrixBathroom.MORE_THAN_ONE_BATH.toLowerCase()}`,
    value: EquipmentMatrixBathroom.MORE_THAN_ONE_BATH,
  },
  {
    label: `equipmentMatrix.bathroom.${EquipmentMatrixBathroom.ONE_BATH.toLowerCase()}`,
    value: EquipmentMatrixBathroom.ONE_BATH,
  },
]

export const bathroomETW = [
  {
    label: `equipmentMatrix.bathroom.${EquipmentMatrixBathroom.INSIDE.toLowerCase()}`,
    value: EquipmentMatrixBathroom.INSIDE,
  },
  {
    label: `equipmentMatrix.bathroom.${EquipmentMatrixBathroom.MORE_THAN_ONE_BATH.toLowerCase()}`,
    value: EquipmentMatrixBathroom.MORE_THAN_ONE_BATH,
  },
  {
    label: `equipmentMatrix.bathroom.${EquipmentMatrixBathroom.WITH_WINDOW.toLowerCase()}`,
    value: EquipmentMatrixBathroom.WITH_WINDOW,
  },
]
