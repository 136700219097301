import React from 'react'
import {
  StyledAddressContainer,
  StyledThumb,
  StyledAddress,
  StyledAddressRow,
} from './Address.styled'
import { useTranslation } from 'react-i18next'

const Address = ({ data, onClickThumb }: any) => {
  const { t } = useTranslation()

  return (
    <div>
      <StyledAddressContainer>
        {data?.propertyDetails?.reportImage && (
          <StyledThumb onClick={onClickThumb}>
            <img src={`data:image/jpeg;base64,${data.propertyDetails.reportImage}`} alt="thumb" />
          </StyledThumb>
        )}
        <StyledAddress>
          <StyledAddressRow>
            {data.street} {data.houseNumber}
          </StyledAddressRow>
          <StyledAddressRow>
            {data.zip} {data.town}, {data.nation} |{' '}
            {t(`buildingType.${data.propertyDetails.buildingType.toLowerCase()}`)}{' '}
            <p>{t(`objectType.${data.propertyDetails.objectType.toLowerCase()}.tag`)}</p>
          </StyledAddressRow>
          <StyledAddressRow>
            {data.lat?.toFixed(6)}, {data.lng?.toFixed(6)}
          </StyledAddressRow>
        </StyledAddress>
      </StyledAddressContainer>
    </div>
  )
}

export default Address
