import React, { ChangeEvent, useEffect } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import styled from 'styled-components'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { useTranslation } from 'react-i18next'
import { TextField } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
)

const StyledMultiSelect = styled.div<any>``

export default function MultiSelect({
  defaultValue,
  options,
  onChangeProps,
  primary,
  value,
  validation,
  ...props
}: any): any {
  const classes = useStyles()
  const { t } = useTranslation()

  useEffect(() => {
    if (validation) {
      validation(value, options)
    }
  }, [value, options]) // eslint-disable-line

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    onChangeProps(event.target.value as string)
  }

  return (
    <StyledMultiSelect>
      <FormControl className={classes.formControl}>
        <TextField
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={value}
          select
          onChange={handleChange}
          variant="outlined"
          {...props}
        >
          {options
            .sort((a, b) => t(a.label).localeCompare(t(b.label)))
            .map((option, index) => (
              <MenuItem key={option.label + index} value={option.value}>
                {t(option.label)}
              </MenuItem>
            ))}
        </TextField>
      </FormControl>
    </StyledMultiSelect>
  )
}
