import React from 'react'
import dayjs from 'common/utils/defaultDayjs'
import { useTranslation } from 'react-i18next'
import { StyledContainer } from './AnonymizationCounter.styled'
import AccessTimeIcon from '@material-ui/icons//AccessTime'

const AnonymizationCounter = (props: any) => {
  const { t } = useTranslation()
  const { leadCreationTime, leadName } = props
  const anonymizedFlag = 'ANONYMIZED'
  const isAnonymized = leadName === anonymizedFlag
  const anonymizationDays = 90

  const getDaysTillAnonymization = () => {
    const currentTime = dayjs()
    const creationTime = dayjs(leadCreationTime, 'yyyy-MM-dd')
    const leadDaysOld = currentTime.diff(creationTime, 'day')
    const daysTillAnonymizationDiff = anonymizationDays - leadDaysOld
    return daysTillAnonymizationDiff > 0 ? daysTillAnonymizationDiff : 1
  }

  const beforeAnonymizationBody = (
    <>
      <AccessTimeIcon />
      <p>
        {t('leadModal.anonymizationMessageBefore1') + ' '}
        <span>
          {t('leadModal.anonymizationMessageBeforeDays', { days: getDaysTillAnonymization() })}
        </span>
        {' ' + t('leadModal.anonymizationMessageBefore2')}
      </p>
    </>
  )

  const afterAnonymizationBody = (
    <p style={{ color: '#92969a' }}>{t('leadModal.anonymizationMessageAfter')}</p>
  )

  return (
    <StyledContainer>
      {isAnonymized ? afterAnonymizationBody : beforeAnonymizationBody}
    </StyledContainer>
  )
}

export default AnonymizationCounter
