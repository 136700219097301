export enum EquipmentValue {
  VERY_SIMPLE = 'VERY_SIMPLE',
  SIMPLE = 'SIMPLE',
  SIMPLE_TO_MEDIUM = 'SIMPLE_TO_MEDIUM',
  MEDIUM = 'MEDIUM',
  MEDIUM_TO_HIGH = 'MEDIUM_TO_HIGH',
  HIGH = 'HIGH',
  HIGH_TO_VERY_HIGH = 'HIGH_TO_VERY_HIGH',
  VERY_HIGH = 'VERY_HIGH',
  VERY_VERY_HIGH = 'VERY_VERY_HIGH',
}
