import React, { useEffect, useState } from 'react'
import MultiSelect from 'components/MultiSelect/MultiSelect'
import { useSelector } from 'react-redux'
import {
  StyledMultiSelectionCol,
  StyledMultiSelectionRow,
  StyledMultiSelectionColHeating,
  StyledMultiSelectionColWindows,
  StyledMultiSelectionColStoreRoom,
  StyledMultiSelectionColFloor,
  StyledMultiSelectionRoofCovering,
} from './AddEquipmentMultiSelect.styled'
import {
  heatingType,
  roofCoveringEFHandMFH,
  storeETW,
  storeMFH,
  floorETW,
  bathroomEFH,
  bathroomETW,
  windowsType,
  residentialAreaETW,
  residentialAreaMFH,
} from './helper'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'store/hooks'
import {
  selectAddressFormData,
  setEquipmentDetailsChanged,
  setFormField,
} from 'slices/addressFormSlice'
import { ObjectType } from 'types/ApiResponse'

function AddEquipmentMultiSelect() {
  const dispatch = useAppDispatch()
  const [validation, setValidationError] = useState(undefined)
  const { t } = useTranslation()
  const addressFormData = useSelector(selectAddressFormData)

  useEffect(() => {
    setValidationError({
      'propertyDetails.equipmentMatrix.heating': undefined,
      'propertyDetails.equipmentMatrix.roofCovering': undefined,
      'propertyDetails.equipmentMatrix.windows': undefined,
      'propertyDetails.equipmentMatrix.storeRoom': undefined,
      'propertyDetails.equipmentMatrix.floorCovering': undefined,
    })
  }, [])

  const onChangeHeatingChange = (heating: boolean) => {
    dispatch(setFormField({ key: 'propertyDetails.equipmentMatrix.heating', value: heating }))
    dispatch(setEquipmentDetailsChanged(true))
  }
  const onChangeRoofCovering = (roofCovering: boolean) => {
    dispatch(
      setFormField({ key: 'propertyDetails.equipmentMatrix.roofCovering', value: roofCovering })
    )
    dispatch(setEquipmentDetailsChanged(true))
  }
  const onChangeWindows = (windows: boolean) => {
    dispatch(setFormField({ key: 'propertyDetails.equipmentMatrix.windows', value: windows }))
    dispatch(setEquipmentDetailsChanged(true))
  }
  const onStoreRoomChange = (storeRoom: boolean) => {
    dispatch(setFormField({ key: 'propertyDetails.equipmentMatrix.storeRoom', value: storeRoom }))
    dispatch(setEquipmentDetailsChanged(true))
  }
  const onFloorChange = (floorCovering: boolean) => {
    dispatch(
      setFormField({ key: 'propertyDetails.equipmentMatrix.floorCovering', value: floorCovering })
    )
    dispatch(setEquipmentDetailsChanged(true))
  }
  const onChangeOutdoor = (residentialArea: string) => {
    dispatch(
      setFormField({
        key: 'propertyDetails.equipmentMatrix.residentialArea',
        value: residentialArea,
      })
    )
    dispatch(setEquipmentDetailsChanged(true))
  }
  const onChangeBathRoom = (bathroom: string) => {
    dispatch(setFormField({ key: 'propertyDetails.equipmentMatrix.bathroom', value: bathroom }))
    dispatch(setEquipmentDetailsChanged(true))
  }

  return (
    <StyledMultiSelectionRow>
      <StyledMultiSelectionCol>
        <StyledMultiSelectionColHeating
          validationError={!!validation?.['propertyDetails.equipmentMatrix.heating']}
        >
          <MultiSelect
            options={heatingType}
            onChange={(event) => onChangeHeatingChange(event.target.value)}
            label={`${t('newAddressModal.furnishingTab.heatingOptions.label')}`}
            value={addressFormData.addressData?.propertyDetails?.equipmentMatrix?.heating}
          />
        </StyledMultiSelectionColHeating>
      </StyledMultiSelectionCol>
      {addressFormData.addressData?.propertyDetails?.objectType !== ObjectType.APARTMENT ? (
        <StyledMultiSelectionCol>
          <StyledMultiSelectionRoofCovering
            validationError={!!validation?.['propertyDetails.equipmentMatrix.roofCovering']}
          >
            <MultiSelect
              options={roofCoveringEFHandMFH}
              onChange={(event) => onChangeRoofCovering(event.target.value)}
              value={addressFormData.addressData?.propertyDetails?.equipmentMatrix?.roofCovering}
              label={`${t('newAddressModal.furnishingTab.roofOptions.label')}`}
            />
          </StyledMultiSelectionRoofCovering>
        </StyledMultiSelectionCol>
      ) : null}
      <StyledMultiSelectionCol>
        <StyledMultiSelectionColWindows
          validationError={!!validation?.['propertyDetails.equipmentMatrix.windows']}
        >
          <MultiSelect
            options={windowsType}
            onChange={(event) => onChangeWindows(event.target.value)}
            value={addressFormData.addressData?.propertyDetails?.equipmentMatrix?.windows}
            label={`${t('newAddressModal.furnishingTab.windowsOptions.label')}`}
          />
        </StyledMultiSelectionColWindows>
      </StyledMultiSelectionCol>
      {addressFormData.addressData?.propertyDetails?.objectType === ObjectType.APARTMENT ||
      addressFormData.addressData?.propertyDetails?.objectType === ObjectType.MULTI_FAMILY_HOUSE ? (
        <StyledMultiSelectionCol>
          <StyledMultiSelectionColStoreRoom
            validationError={!!validation?.['propertyDetails.equipmentMatrix.storeRoom']}
          >
            <MultiSelect
              options={
                addressFormData.addressData?.propertyDetails?.objectType === ObjectType.APARTMENT
                  ? storeETW
                  : storeMFH
              }
              onChange={(event) => onStoreRoomChange(event.target.value)}
              value={addressFormData.addressData?.propertyDetails?.equipmentMatrix?.storeRoom}
              label={`${t('newAddressModal.furnishingTab.storageOptions.label')}`}
            />
          </StyledMultiSelectionColStoreRoom>
        </StyledMultiSelectionCol>
      ) : null}
      {addressFormData.addressData?.propertyDetails?.objectType === ObjectType.APARTMENT ? (
        <StyledMultiSelectionCol>
          <StyledMultiSelectionColFloor
            validationError={!!validation?.['propertyDetails.equipmentMatrix.floorCovering']}
          >
            <MultiSelect
              options={floorETW}
              onChange={(event) => onFloorChange(event.target.value)}
              value={addressFormData.addressData?.propertyDetails?.equipmentMatrix?.floorCovering}
              label={`${t('newAddressModal.furnishingTab.floorCoveringOptions.label')}`}
            />
          </StyledMultiSelectionColFloor>
        </StyledMultiSelectionCol>
      ) : null}
      {addressFormData.addressData?.propertyDetails?.objectType !== ObjectType.HOUSE ? (
        <StyledMultiSelectionCol>
          <MultiSelect
            options={
              addressFormData.addressData?.propertyDetails?.objectType === ObjectType.APARTMENT
                ? residentialAreaETW
                : residentialAreaMFH
            }
            validationErrorCheckbox={
              !!validation?.['propertyDetails.equipmentMatrix.residentialArea']
            }
            onChange={(event) => onChangeOutdoor(event.target.value)}
            value={addressFormData.addressData?.propertyDetails?.equipmentMatrix?.residentialArea}
            label={`${t('newAddressModal.furnishingTab.outdoorLivingAreaOptions.label')}`}
          />
        </StyledMultiSelectionCol>
      ) : null}
      {addressFormData.addressData?.propertyDetails?.objectType !==
      ObjectType.MULTI_FAMILY_HOUSE ? (
        <StyledMultiSelectionCol>
          <MultiSelect
            options={
              addressFormData.addressData?.propertyDetails?.objectType === ObjectType.HOUSE
                ? bathroomEFH
                : bathroomETW
            }
            validationErrorCheckbox={!!validation?.['propertyDetails.equipmentMatrix.bathroom']}
            onChange={(event) => onChangeBathRoom(event.target.value)}
            value={addressFormData.addressData?.propertyDetails?.equipmentMatrix?.bathroom}
            label={`${t('newAddressModal.furnishingTab.bathOptions.label')}`}
          />
        </StyledMultiSelectionCol>
      ) : null}
    </StyledMultiSelectionRow>
  )
}

export default AddEquipmentMultiSelect
