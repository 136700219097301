import { HouseApartmentType } from 'types/ApiResponse'

const confirmText = 'common.yes'
const declineText = 'common.no'

const sharedOptions = [
  { key: 'true', value: confirmText, disabled: false },
  { key: 'false', value: declineText, disabled: false },
]

export const garageSelect = sharedOptions

export const parkingSpace = sharedOptions

export const elevatorSelect = sharedOptions

export const includeContactDetailsSelect = sharedOptions

export const reportTypeSelect = [
  {
    key: 'VALUATION',
    value: 'newAddressModal.objectDataTab.reportType.valuation',
    disabled: false,
  },
  { key: 'RENT', value: 'newAddressModal.objectDataTab.reportType.rent', disabled: false },
]

export const periodTypeSelect = [
  {
    key: 'YEAR_1',
    value: 'newAddressModal.objectDataTab.periodType.1_year',
    disabled: false,
  },
  {
    key: 'YEAR_2',
    value: 'newAddressModal.objectDataTab.periodType.2_year',
    disabled: false,
  },
  {
    key: 'YEAR_5',
    value: 'newAddressModal.objectDataTab.periodType.5_year',
    disabled: false,
  },
  {
    key: 'YEAR_10',
    value: 'newAddressModal.objectDataTab.periodType.10_year',
    disabled: false,
  },
]

export const hausTypeEFHandMFH = [
  {
    label: `houseOrApartmentType.${HouseApartmentType.SEMI_DETACHED_HOUSE.toLowerCase()}`,
    value: HouseApartmentType.SEMI_DETACHED_HOUSE,
  },
  {
    label: `houseOrApartmentType.${HouseApartmentType.DETACHED_HOUSE.toLowerCase()}`,
    value: HouseApartmentType.DETACHED_HOUSE,
  },
  {
    label: `houseOrApartmentType.${HouseApartmentType.END_TERRACED_HOUSE.toLowerCase()}`,
    value: HouseApartmentType.END_TERRACED_HOUSE,
  },
  {
    label: `houseOrApartmentType.${HouseApartmentType.MID_TERRACED_HOUSE.toLowerCase()}`,
    value: HouseApartmentType.MID_TERRACED_HOUSE,
  },
]

export const hausTypeETW = [
  {
    label: `houseOrApartmentType.${HouseApartmentType.ATTIC_APARTMENT.toLowerCase()}`,
    value: HouseApartmentType.ATTIC_APARTMENT,
  },
  {
    label: `houseOrApartmentType.${HouseApartmentType.GROUND_FLOOR_APARTMENT.toLowerCase()}`,
    value: HouseApartmentType.GROUND_FLOOR_APARTMENT,
  },
  {
    label: `houseOrApartmentType.${HouseApartmentType.MID_FLOOR_APARTMENT.toLowerCase()}`,
    value: HouseApartmentType.MID_FLOOR_APARTMENT,
  },
  {
    label: `houseOrApartmentType.${HouseApartmentType.RAISED_FIRST_FLOOR_APARTMENT.toLowerCase()}`,
    value: HouseApartmentType.RAISED_FIRST_FLOOR_APARTMENT,
  },
  {
    label: `houseOrApartmentType.${HouseApartmentType.LOFT.toLowerCase()}`,
    value: HouseApartmentType.LOFT,
  },
  {
    label: `houseOrApartmentType.${HouseApartmentType.TWO_FLOOR_APARTMENT.toLowerCase()}`,
    value: HouseApartmentType.TWO_FLOOR_APARTMENT,
  },
  {
    label: `houseOrApartmentType.${HouseApartmentType.PENTHOUSE.toLowerCase()}`,
    value: HouseApartmentType.PENTHOUSE,
  },
  {
    label: `houseOrApartmentType.${HouseApartmentType.BASEMENT_APARTMENT.toLowerCase()}`,
    value: HouseApartmentType.BASEMENT_APARTMENT,
  },
  {
    label: `houseOrApartmentType.${HouseApartmentType.TERRACED_APARTMENT.toLowerCase()}`,
    value: HouseApartmentType.TERRACED_APARTMENT,
  },
]

export const hausTypeETW_AT = [
  {
    label: `houseOrApartmentType.${HouseApartmentType.ATTIC_APARTMENT.toLowerCase()}`,
    value: HouseApartmentType.ATTIC_APARTMENT,
  },
  {
    label: `houseOrApartmentType.${HouseApartmentType.ATTIC_PENTHOUSE.toLowerCase()}`,
    value: HouseApartmentType.ATTIC_PENTHOUSE,
  },
  {
    label: `houseOrApartmentType.${HouseApartmentType.GROUND_FLOOR_APARTMENT.toLowerCase()}`,
    value: HouseApartmentType.GROUND_FLOOR_APARTMENT,
  },
  {
    label: `houseOrApartmentType.${HouseApartmentType.MID_FLOOR_APARTMENT.toLowerCase()}`,
    value: HouseApartmentType.MID_FLOOR_APARTMENT,
  },
  {
    label: `houseOrApartmentType.${HouseApartmentType.LOFT.toLowerCase()}`,
    value: HouseApartmentType.LOFT,
  },
  {
    label: `houseOrApartmentType.${HouseApartmentType.TWO_FLOOR_APARTMENT.toLowerCase()}`,
    value: HouseApartmentType.TWO_FLOOR_APARTMENT,
  },
  {
    label: `houseOrApartmentType.${HouseApartmentType.APARTMENT_WITH_GARDEN.toLowerCase()}`,
    value: HouseApartmentType.APARTMENT_WITH_GARDEN,
  },
  {
    label: `houseOrApartmentType.${HouseApartmentType.PENTHOUSE.toLowerCase()}`,
    value: HouseApartmentType.PENTHOUSE,
  },
  {
    label: `houseOrApartmentType.${HouseApartmentType.BASEMENT_APARTMENT.toLowerCase()}`,
    value: HouseApartmentType.BASEMENT_APARTMENT,
  },
  {
    label: `houseOrApartmentType.${HouseApartmentType.TERRACED_APARTMENT.toLowerCase()}`,
    value: HouseApartmentType.TERRACED_APARTMENT,
  },
]
