import React from 'react'
import { StyledEquipmentMethod, StyledEquipmentPanel } from './AddEquipment.styled'
import AddEquipmentMultiSelect from './AddEquipmentMultiSelect/AddEquipmentMultiSelect'
import AddEquipmentRadioGroup from './AddEquipmentRadioGroup/AddEquipmentRadioGroup'
import AddEquipmentLevelSlider from './AddEquipmentLevelSlider/AddEquipmentLevelSlider'
import RadioGroupButtons from 'components/RadioGroupButtons/RadioGroupButtons'
import { useSelector } from 'react-redux'
import { StyledLine } from '../AddObjectData/AddObjectData.styled'
import { EquipmentMethod } from 'types/EquipmentMethod'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'store/hooks'
import { selectAddressFormData, setFormField } from 'slices/addressFormSlice'

const equipmentMethods = [
  {
    key: EquipmentMethod.SIMPLE,
    value: 'newAddressModal.furnishingTab.equipmentMethod.simple',
  },
  {
    key: EquipmentMethod.DETAILED,
    value: 'newAddressModal.furnishingTab.equipmentMethod.detailed',
  },
]

function AddEquipment() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const addressFormData = useSelector(selectAddressFormData)

  const handleChangeEquipmentMethodChange = (value) => {
    dispatch(setFormField({ key: 'propertyDetails.equipmentMethod', value }))
  }

  if (addressFormData.addressData?.propertyDetails?.equipmentMethod === undefined) {
    dispatch(
      setFormField({ key: 'propertyDetails.equipmentMethod', value: EquipmentMethod.SIMPLE })
    )
  }

  return (
    <>
      <StyledEquipmentPanel>
        <StyledEquipmentMethod style={{ marginTop: 30 }}>
          <RadioGroupButtons
            options={equipmentMethods}
            label={t('newAddressModal.furnishingTab.equipmentMethod.label')}
            onChange={(event) => handleChangeEquipmentMethodChange(event.target.value)}
            value={
              addressFormData.addressData?.propertyDetails?.equipmentMethod ||
              EquipmentMethod.SIMPLE
            }
          />
        </StyledEquipmentMethod>
        <StyledLine />
        {addressFormData.addressData?.propertyDetails?.equipmentMethod ===
          EquipmentMethod.SIMPLE && <AddEquipmentLevelSlider />}
        {addressFormData.addressData?.propertyDetails?.equipmentMethod ===
          EquipmentMethod.DETAILED && (
          <>
            <AddEquipmentMultiSelect />
            <AddEquipmentRadioGroup />
          </>
        )}
      </StyledEquipmentPanel>
    </>
  )
}

export default AddEquipment
