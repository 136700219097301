import React from 'react'
import { EquipmentValue } from 'types/EquipmentValue'
import { useTranslation } from 'react-i18next'
import { StyledLevelDescription, StyledSlider } from './AddEquipmentLevelSlider.styled'
import { useSelector } from 'react-redux'
import { selectAddressFormData, setFormField } from 'slices/addressFormSlice'
import { useAppDispatch } from 'store/hooks'

const EQUIPMENT_VALUE_MAP = {
  10: EquipmentValue.VERY_SIMPLE,
  20: EquipmentValue.SIMPLE,
  30: EquipmentValue.SIMPLE_TO_MEDIUM,
  40: EquipmentValue.MEDIUM,
  50: EquipmentValue.MEDIUM_TO_HIGH,
  60: EquipmentValue.HIGH,
  70: EquipmentValue.HIGH_TO_VERY_HIGH,
  80: EquipmentValue.VERY_HIGH,
  90: EquipmentValue.VERY_VERY_HIGH,
}

const getEquipmentValue = (value: number) => {
  return EQUIPMENT_VALUE_MAP[value] || EquipmentValue.MEDIUM_TO_HIGH
}

const getEquipmentValueNumber = (equipmentValue?: EquipmentValue) => {
  return (
    Object.keys(EQUIPMENT_VALUE_MAP).filter(
      (key) => EQUIPMENT_VALUE_MAP[key] === equipmentValue
    )[0] || 40
  )
}

const AddEquipmentLevelSlider = (props: any) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const addressFormData = useSelector(selectAddressFormData)
  const marks = [
    {
      value: 10,
      label: <p>{t(`equipmentValue.${EquipmentValue.VERY_SIMPLE.toLowerCase()}`)}</p>,
    },
    {
      value: 20,
      label: <p>{t(`equipmentValue.${EquipmentValue.SIMPLE.toLowerCase()}`)}</p>,
    },
    {
      value: 30,
      label: <p>{t(`equipmentValue.${EquipmentValue.SIMPLE_TO_MEDIUM.toLowerCase()}`)}</p>,
    },
    {
      value: 40,
      label: <p>{t(`equipmentValue.${EquipmentValue.MEDIUM.toLowerCase()}`)}</p>,
    },
    {
      value: 50,
      label: <p>{t(`equipmentValue.${EquipmentValue.MEDIUM_TO_HIGH.toLowerCase()}`)}</p>,
    },
    {
      value: 60,
      label: <p>{t(`equipmentValue.${EquipmentValue.HIGH.toLowerCase()}`)}</p>,
    },
    {
      value: 70,
      label: <p>{t(`equipmentValue.${EquipmentValue.HIGH_TO_VERY_HIGH.toLowerCase()}`)}</p>,
    },
    {
      value: 80,
      label: <p>{t(`equipmentValue.${EquipmentValue.VERY_HIGH.toLowerCase()}`)}</p>,
    },
    {
      value: 90,
      label: <p>{t(`equipmentValue.${EquipmentValue.VERY_VERY_HIGH.toLowerCase()}`)}</p>,
    },
  ]

  const handleChange = (_: Event, newValue: number) => {
    dispatch(
      setFormField({ key: 'propertyDetails.equipmentValue', value: getEquipmentValue(newValue) })
    )
  }

  if (addressFormData.addressData?.propertyDetails?.equipmentValue === undefined) {
    dispatch(
      setFormField({ key: 'propertyDetails.equipmentValue', value: EquipmentValue.MEDIUM_TO_HIGH })
    )
  }

  return (
    <div style={{ marginTop: 100 }}>
      <StyledSlider
        marks={marks}
        min={5}
        max={95}
        step={null}
        track={false}
        defaultValue={getEquipmentValueNumber(
          addressFormData.addressData?.propertyDetails?.equipmentValue
        )}
        onChange={handleChange}
      />
      {addressFormData.addressData?.propertyDetails?.equipmentValue !== undefined && (
        <StyledLevelDescription>
          <b>
            {t(
              `equipmentValue.${addressFormData.addressData?.propertyDetails?.equipmentValue.toLowerCase()}`
            )}
            :
          </b>{' '}
          {t(
            `equipmentValue.${addressFormData.addressData?.propertyDetails?.equipmentValue.toLowerCase()}.description`
          )}
        </StyledLevelDescription>
      )}
    </div>
  )
}

export default AddEquipmentLevelSlider
