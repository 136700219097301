import React from 'react'
import checkMarkYes from 'assets/images/icons/checkMarkYes.svg'
import checkMarkNo from 'assets/images/icons/checkMarkNo.svg'
import {
  StyledPropertyCol,
  StyledPropertyRow,
  StyledPropertyUnit,
  StyledPropertyValue,
} from './HouseProperty.styled'
import { useTranslation } from 'react-i18next'
import { EquipmentMethod } from 'types/EquipmentMethod'

const HouseProperty = ({ data }: any) => {
  const { t } = useTranslation()
  const confirmText = t('common.yes')
  const declineText = t('common.no')

  const getEquipmentMethodName = (equipmentMethod) => {
    switch (equipmentMethod) {
      case EquipmentMethod.SIMPLE:
        return t('newAddressModal.furnishingTab.equipmentMethod.simple')
      case EquipmentMethod.DETAILED:
        return t('newAddressModal.furnishingTab.equipmentMethod.detailed')
      default:
        return '—'
    }
  }

  return (
    <>
      <StyledPropertyRow>
        <StyledPropertyCol>
          <label>{t('newAddressModal.objectDataTab.houseType')}</label>
          <StyledPropertyValue>
            <span>
              {data?.propertyDetails?.houseOrApartmentType
                ? t(
                    `houseOrApartmentType.${data?.propertyDetails?.houseOrApartmentType?.toLowerCase()}`
                  )
                : '—'}
            </span>
          </StyledPropertyValue>
        </StyledPropertyCol>
      </StyledPropertyRow>
      <StyledPropertyRow>
        <StyledPropertyCol>
          <label>{t('newAddressModal.objectDataTab.buildYear')}</label>
          <StyledPropertyValue>
            {data?.propertyDetails?.constructionYear
              ? data?.propertyDetails?.constructionYear
              : '—'}
          </StyledPropertyValue>
        </StyledPropertyCol>
        <StyledPropertyCol>
          <label>{t('newAddressModal.objectDataTab.livingArea')}</label>
          <StyledPropertyValue>
            {data?.propertyDetails?.livingArea ? data?.propertyDetails?.livingArea : '—'}
            <StyledPropertyUnit>m2</StyledPropertyUnit>
          </StyledPropertyValue>
        </StyledPropertyCol>
        <StyledPropertyCol>
          <label>{t('newAddressModal.objectDataTab.plotArea')}</label>
          <StyledPropertyValue>
            {data?.propertyDetails?.plotArea ? data?.propertyDetails?.plotArea : '—'}
            <StyledPropertyUnit>m2</StyledPropertyUnit>
          </StyledPropertyValue>
        </StyledPropertyCol>
        <StyledPropertyCol>
          <label>{t('newAddressModal.objectDataTab.rooms')}</label>
          <StyledPropertyValue>
            {data?.propertyDetails?.rooms ? data?.propertyDetails?.rooms : '—'}
          </StyledPropertyValue>
        </StyledPropertyCol>
        <StyledPropertyCol>
          <label>{t('addressModal.floors')}</label>
          <StyledPropertyValue>
            {data?.propertyDetails?.floorNumber || data?.propertyDetails?.numberOfFloors
              ? data?.propertyDetails?.floorNumber || data?.propertyDetails?.numberOfFloors
              : '—'}
          </StyledPropertyValue>
        </StyledPropertyCol>
      </StyledPropertyRow>
      <StyledPropertyRow>
        <StyledPropertyCol>
          <label>{t('addressModal.equipmentLevel')}</label>
          <StyledPropertyValue>
            <span>
              {data?.propertyDetails?.equipmentValue
                ? t(`equipmentValue.${data?.propertyDetails?.equipmentValue.toLowerCase()}`)
                : '—'}
            </span>
          </StyledPropertyValue>
        </StyledPropertyCol>
        <StyledPropertyCol>
          <label>{t('addressModal.equipmentMethod')}</label>
          <StyledPropertyValue>
            <span>{getEquipmentMethodName(data?.propertyDetails?.equipmentMethod)}</span>
          </StyledPropertyValue>
        </StyledPropertyCol>
        <StyledPropertyCol>
          <label>{t('newAddressModal.objectDataTab.garage')}</label>
          <StyledPropertyValue>
            {data.propertyDetails?.garages && <img src={checkMarkYes} alt="checkMarkYes" />}
            {data.propertyDetails?.garages === false && <img src={checkMarkNo} alt="checkMarkNo" />}
            {data?.propertyDetails?.garages
              ? confirmText
              : data.propertyDetails?.garages === null
              ? '—'
              : data.propertyDetails?.garages === false
              ? declineText
              : '—'}
          </StyledPropertyValue>
        </StyledPropertyCol>
        <StyledPropertyCol>
          <label>{t('newAddressModal.objectDataTab.parkingPlace')}</label>
          <StyledPropertyValue>
            {data.propertyDetails?.outdoorParkingSpace ? (
              <img src={checkMarkYes} alt="checkMarkYes" />
            ) : data.propertyDetails?.outdoorParkingSpace === false ? (
              <img src={checkMarkNo} alt="checkMarkNo" />
            ) : null}
            {data?.propertyDetails?.outdoorParkingSpace
              ? confirmText
              : data.propertyDetails?.outdoorParkingSpace === null
              ? '—'
              : data.propertyDetails?.outdoorParkingSpace === false
              ? declineText
              : '—'}
          </StyledPropertyValue>
        </StyledPropertyCol>
        <StyledPropertyCol>
          <label>{t('newAddressModal.objectDataTab.elevator')}</label>
          <StyledPropertyValue>
            {data.propertyDetails?.elevator && <img src={checkMarkYes} alt="checkMarkYes" />}
            {data.propertyDetails?.elevator === false && (
              <img src={checkMarkNo} alt="checkMarkNo" />
            )}
            {data?.propertyDetails?.elevator
              ? confirmText
              : data.propertyDetails?.elevator === null
              ? '—'
              : data.propertyDetails?.elevator === false
              ? declineText
              : '—'}
          </StyledPropertyValue>
        </StyledPropertyCol>
      </StyledPropertyRow>
    </>
  )
}

export default HouseProperty
