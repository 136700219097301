import { NotificationType } from 'components/Notifications/NotificationItem/NotificationItem'
import { BatchStatus } from './BatchStatus'
import { BatchType } from './BatchType'
import { EquipmentMethod } from './EquipmentMethod'
import { IndexReportType } from './IndexReportType'
import { IndexPeriodType } from './IndexPeriodType'
import { EquipmentValue } from './EquipmentValue'

export type SortResponseType = {
  empty: boolean
  sorted: boolean
  unsorted: boolean
}

export type PageableResponseType = {
  offset: number
  pageNumber: number
  pageSize: number
  paged: boolean
  sort: SortResponseType
  unpaged: boolean
}

export type ApiListResponseType<T> = {
  content: T[]
  empty: boolean
  first: boolean
  last: boolean
  number: number
  numberOfElements: number
  size: number
  totalElements: number
  totalPages: number
  sort: SortResponseType
  pageable: PageableResponseType
}

export type BatchEntityType = {
  uuid: string
  name: string
  type: BatchType
  status: BatchStatus
  batchSize: number
  failedRowsSize: number | null
  creationTime: number
  processingStartTime: number | null
  processingEndTime: number | null
}

export type CoinHistoryType = {
  transactionDate: string
  origin: string
  type: string
  delta: number
  product: string | null
}

export type NotificationEntityType = {
  id: string
  name: string
  type: BatchType
  status: NotificationType
  startDate: number
  text: string
  image: string
  url: string
  newWindow: boolean
  popUp: boolean
}

export type ProductType = {
  id: number
  active: boolean
  name: string
  url: string | null
  other: boolean
  newTab: boolean
  urlMarketing: string | null
  emailSender: string | null
  emailProvider: string | null
  imageUrl: string | null
  modified: boolean
  batchTemplateUrl: string | null
  batchMaxSize: number | null
  billing: boolean | null
  coinBased: boolean | null
  subscriptionBased: boolean | null
  coinPrice: number | null
  hoverColor: string | null
}

export type CoordinatesType = {
  lat: number | null
  lng: number | null
}

export type SubscriptionType = {
  id: string
  title: string
  products: string[]
}

export type CustomerConfigurationEntityType = {
  name: string
  reportUser: string | null
  reportPassword: string | null
  mapSystemId: string | null
  products: ProductType[]
  mapOptions: CoordinatesType
  isDefault: boolean | null
  immopointsEnabled: boolean | null
  configuration: Record<string, string> | null
  userConfiguration: Record<string, string> | null
  subscriptions: SubscriptionType[] | null
}

export type CustomerCoinsEntityType = {
  immoPoints: number | null
  accounts: CoinsType[]
}

export type CoinsType = {
  type: string | null
  amount: number | null
  nextRechargeAt: number | null
  nextResetAt: number | null
}

export type BasicAddressEntityType = {
  id: number
  nation: string | null
  zip: string | null
  town: string | null
  street: string | null
  houseNumber: string | null
  objectType: ObjectType | null
  importedFrom: string | null
  mapCreated: boolean | null
  reportCreated: boolean | null
  indexCreated: boolean | null
  indexCsvAvailable: boolean | null
  creationTime: number
  isCopy: boolean
}

export type AddressEntityType = {
  id: number
  nation: string | null
  zip: string | null
  town: string | null
  street: string | null
  houseNumber: string | null
  lat: number | null
  lng: number | null
  importedFrom: string | null
  mapCreated: boolean | null
  reportCreated: boolean | null
  indexCreated: boolean | null
  indexCsvAvailable: boolean | null
  isCopy: boolean
  propertyDetails: PropertyDetailsEntityType | null
  leadDetails: LeadDetailsEntityType | null
  indexDetails: IndexDetailsEntityType | null
  creationTime: number
}

export enum ObjectType {
  PLOT_OF_LAND = 'PLOT_OF_LAND',
  HOUSE = 'HOUSE',
  APARTMENT = 'APARTMENT',
  MULTI_FAMILY_HOUSE = 'MULTI_FAMILY_HOUSE',
}

export enum BuildingType {
  SINGLE_FAMILY_HOUSE = 'SINGLE_FAMILY_HOUSE',
  SINGLE_FAMILY_HOUSE_WITH_GRANNY_APARTMENT = 'SINGLE_FAMILY_HOUSE_WITH_GRANNY_APARTMENT',
  TWO_FAMILY_HOUSE = 'TWO_FAMILY_HOUSE',
  APARTMENT = 'APARTMENT',
  MULTI_FAMILY_HOUSE = 'MULTI_FAMILY_HOUSE',
  RESIDENTIAL_AND_COMMERCIAL = 'RESIDENTIAL_AND_COMMERCIAL',
}

export enum HouseApartmentType {
  DETACHED_HOUSE = 'DETACHED_HOUSE',
  SEMI_DETACHED_HOUSE = 'SEMI_DETACHED_HOUSE',
  END_TERRACED_HOUSE = 'END_TERRACED_HOUSE',
  MID_TERRACED_HOUSE = 'MID_TERRACED_HOUSE',
  LOFT = 'LOFT',
  PENTHOUSE = 'PENTHOUSE',
  BASEMENT_APARTMENT = 'BASEMENT_APARTMENT',
  GROUND_FLOOR_APARTMENT = 'GROUND_FLOOR_APARTMENT',
  MID_FLOOR_APARTMENT = 'MID_FLOOR_APARTMENT',
  RAISED_FIRST_FLOOR_APARTMENT = 'RAISED_FIRST_FLOOR_APARTMENT',
  TWO_FLOOR_APARTMENT = 'TWO_FLOOR_APARTMENT',
  TERRACED_APARTMENT = 'TERRACED_APARTMENT',
  ATTIC_APARTMENT = 'ATTIC_APARTMENT',
  ATTIC_PENTHOUSE = 'ATTIC_PENTHOUSE',
  APARTMENT_WITH_GARDEN = 'APARTMENT_WITH_GARDEN',
}

export type PropertyDetailsEntityType = {
  objectType: ObjectType | null
  buildingType: BuildingType | null
  houseOrApartmentType: string | null
  constructionYear: number | null
  livingArea: number | null
  plotArea: number | null
  rooms: number | null
  floorNumber: number | null
  numberOfFloors: number | null
  elevator: boolean | null
  garages: boolean | null
  outdoorParkingSpace: boolean | null
  equipmentMethod: EquipmentMethod | null
  equipmentValue: EquipmentValue | null
  equipmentMatrix: EquipmentMatrixEntityType | null
  reportImage: string | null
  reportImageMimeType: string | null
}

export enum EquipmentMatrixBathroom {
  INSIDE = 'INSIDE',
  MORE_THAN_ONE_BATH = 'MORE_THAN_ONE_BATH',
  WITH_WINDOW = 'WITH_WINDOW',
  ONE_BATH = 'ONE_BATH',
}

export enum EquipmentMatrixRoofCovering {
  ROOFING_FELT = 'ROOFING_FELT',
  ROOFING_TILES = 'ROOFING_TILES',
  METAL = 'METAL',
  SLATE = 'SLATE',
  OTHER = 'OTHER',
}

export enum EquipmentMatrixWindows {
  DOUBLE_GLAZING = 'DOUBLE_GLAZING',
  TRIPLE_GLAZING = 'TRIPLE_GLAZING',
  SPECIAL_GLAZING = 'SPECIAL_GLAZING',
  FLOOR_TO_CEILING_GLAZING = 'FLOOR_TO_CEILING_GLAZING',
  BOX_WINDOW = 'BOX_WINDOW',
  SIMPLE = 'SIMPLE',
  OTHER = 'OTHER',
}

export enum EquipmentMatrixHeating {
  FLOOR_HEATING = 'FLOOR_HEATING',
  STOVES_HEATING = 'STOVES_HEATING',
  CENTRAL_HEATING = 'CENTRAL_HEATING',
  OTHER = 'OTHER',
}

export enum EquipmentMatrixSurfaceMountedInstallation {
  CABLES_ON_WALLS = 'CABLES_ON_WALLS',
  CABLES_NOT_ON_WALLS = 'CABLES_NOT_ON_WALLS',
}

export enum EquipmentMatrixStoreRoom {
  OUTSIDE = 'OUTSIDE',
  INSIDE = 'INSIDE',
  OUTSIDE_AND_INSIDE = 'OUTSIDE_AND_INSIDE',
  NONE = 'NONE',
}

export enum EquipmentMatrixFloorCovering {
  TILES = 'TILES',
  PLASTIC_PVC = 'PLASTIC_PVC',
  PARQUET_NATURAL_STONE = 'PARQUET_NATURAL_STONE',
  CARPET_LAMINATE = 'CARPET_LAMINATE',
  OTHER = 'OTHER',
}

export enum EquipmentMatrixResidentialArea {
  WINTER_GARDEN = 'WINTER_GARDEN',
  BALCONY_ABOVE_10 = 'BALCONY_ABOVE_10',
  BALCONY_UNDER_10 = 'BALCONY_UNDER_10',
  NO_BALCONY = 'NO_BALCONY',
}

export type EquipmentMatrixEntityType = {
  insulatedExteriorWalls: boolean | null
  bathroom: EquipmentMatrixBathroom | null
  roofCovering: EquipmentMatrixRoofCovering | null
  windows: EquipmentMatrixWindows | null
  guestToilet: boolean | null
  heating: EquipmentMatrixHeating | null
  surfaceMountedInstallation: EquipmentMatrixSurfaceMountedInstallation | null
  storeRoom: EquipmentMatrixStoreRoom | null
  floorCovering: EquipmentMatrixFloorCovering | null
  residentialArea: EquipmentMatrixResidentialArea | null
}

export type LeadDetailsEntityType = {
  name: string | null
  surname: string | null
  phone: string | null
  email: string | null
  valuationValue: number | null
  valuationMetaModel: string | null
  valuationMetaStandardError: number | null
  valuationMetaScore: number | null
  valuationMetaRangeMin: number | null
  valuationMetaRangeMax: number | null
  date: string | null
}

export type IndexDetailsEntityType = {
  reportType: IndexReportType | null
  periodType: IndexPeriodType | null
  includeContactDetails: boolean | null
}

export type BatchListApiResponseType = ApiListResponseType<BatchEntityType>

export type AddressListApiResponseType = ApiListResponseType<BasicAddressEntityType>

export type CoinHistoryListApiResponseType = ApiListResponseType<CoinHistoryType>

export type CoinHistoryListFullApiResponseType = CoinHistoryType[]
