import { checkValidationError } from 'common/utils/CheckValidationError'
import { requiredFields } from 'common/utils/RequiredFields'
import { ObjectType } from 'types/ApiResponse'

const getNestedValue = (obj, key) => {
  return key.split('.').reduce((acc, curr) => acc && acc[curr], obj)
}

export const checkReqField = (currentNewLeadData, setValidationError, validationError) => {
  if (currentNewLeadData?.propertyDetails?.objectType === ObjectType.APARTMENT) {
    requiredFields['page2ETW'].forEach((item) => {
      if (checkValidationError(validationError, item)) {
        setValidationError((prevState) => ({ ...prevState, [item]: true }))
      }
      if (getNestedValue(currentNewLeadData, item)) {
        setValidationError((prevState) => ({ ...prevState, [item]: false }))
      }
    })
  }
  if (currentNewLeadData?.propertyDetails?.objectType === ObjectType.HOUSE) {
    requiredFields['page2EFH'].forEach((item) => {
      if (checkValidationError(validationError, item)) {
        setValidationError((prevState) => ({ ...prevState, [item]: true }))
      }
      if (getNestedValue(currentNewLeadData, item)) {
        setValidationError((prevState) => ({ ...prevState, [item]: false }))
      }
    })
  }
  if (currentNewLeadData?.propertyDetails?.objectType === ObjectType.MULTI_FAMILY_HOUSE) {
    requiredFields['page2MFH'].forEach((item) => {
      if (checkValidationError(validationError, item)) {
        setValidationError((prevState) => ({ ...prevState, [item]: true }))
      }
      if (getNestedValue(currentNewLeadData, item)) {
        setValidationError((prevState) => ({ ...prevState, [item]: false }))
      }
    })
  }
}
